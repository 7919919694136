.privacy-policy {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .logo-image {
    max-height: 80px;
    margin-bottom: 20px;
  }
  
  .section-title {
    color: #4A00E0; /* Use a blue shade that is visually pleasing */
    font-weight: 600;
    border-bottom: 1px solid #4A00E0;
    padding-bottom: 5px;
    text-align: left;
  }
  
  p {
    color: #333333;
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  /* RTL language support */
  .rtl {
    direction: rtl;
    text-align: right;
  }