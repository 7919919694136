/* Container Styling */
.call-center-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  font-size: small;
  padding: 1rem;
}

/* Input Group Base Styling */
.input-group-text-call-center {
  background-color: white;
  color: #4A00E0;
  font-size: smaller;
  border-left: none;
  text-align: center;
  font-weight: bold;
}

.input-group-text-call-center-disabled {
  background-color: #e9ecef !important;
  color: #4A00E0;
  font-size: smaller;
  border-left: none;
  text-align: center;
  font-weight: bold;
}

.table-label-left {
  text-align: right;
}

.form-control-call-center {
  font-size: 0.79rem !important;
}

.table-custom-call-center .form-control {
  font-size: 0.79rem !important;
}

.unit {
  margin-left: 0.2rem;
  color: #4A00E0;
  font-weight: bold;
}

.table-custom-call-center th {
  color: black;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  padding: 0.75rem;
}

.table-custom-call-center th:first-child {
  width: 20%;
}

.table-custom-call-center td,
.table-custom-call-center th:not(:first-child) {
  width: 30%;
}

/* TD Styling */
.table-custom-call-center td {
  text-align: right;
  vertical-align: middle;
}

.table-custom-call-center th {
  vertical-align: middle;
  text-align: left;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .form-call-center {
    margin-bottom: 10px;
    padding: 4px;
    font-size: 0.75rem;
  }

  .table-custom-call-center th:first-child {
    width: 50%;
  }

  .table-custom-call-center td,
  .table-custom-call-center th:not(:first-child) {
    width: 25%;
  }

  .input-group {
    align-items: stretch;
  }

  .input-group-text-call-center {
    margin-top: 0.5rem;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .call-center-container {
    padding: 0.5rem;
  }

  .table-custom-call-center th:first-child {
    width: 50%;
  }

  .table-custom-call-center td,
  .table-custom-call-center th:not(:first-child) {
    width: 50%;
  }

  .input-group-text-call-center {
    margin-top: 0.5rem;
    text-align: center;
    width: 100%;
  }
}


.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section-header-left {
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
}

.section-description {
  font-size: 0.875rem;
}

@media (max-width: 576px) {
  .section-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .section-header-left {
    margin-bottom: 1rem;
  }
}