/* General Wrapper */
.login-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #44297b, #4A00E0);
  overflow: hidden;
}

/* Container */
.custom-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
}

/* Sidebar */
.custom-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 20px;
}

/* Logo */
.logo-img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Form Container */
.custom-form-container {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 15px;
}

/* Button */
.custom-button {
  background: linear-gradient(135deg, #6b73ff, #4A00E0);
  border: none;
}

/* Links */
.auth-link {
  color: #7158e2;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 5px;
}

/* Password Toggle */
.password-toggle {
  background: none;
  border: 1px solid #ced4da;
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.password-toggle:focus {
  outline: none;
  box-shadow: none;
}

/* Small Text */
.small-text {
  font-size: 12px;
  color: #4A00E0;
}

/* Footer */
.footer {
  background: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  padding: 10px 20px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  flex-shrink: 0;
}

.footer-text {
  margin: 0;
  font-size: 0.85rem;
  font-weight: 300;
  letter-spacing: 0.5px;
}

/* Responsive Design */
@media (max-width: 767px) {
  .custom-container {
    flex-direction: column;
    padding: 10px;
  }

  .custom-sidebar {
    padding: 15px;
  }

  .logo-vertical {
    max-width: 280px; /* Mobile size */
    margin-top: 250px;
    display: block;
  }

  .logo-horizontal {
    display: none; /* Hide horizontal logo on mobile */
  }

  .custom-form-container {
    padding: 1rem;
    margin: 10px;
  }

  .auth-link {
    font-size: 0.9rem;
  }

  .custom-button {
    font-size: 0.9rem;
  }

  .footer {
    padding: 8px 15px;
  }

  .footer-text {
    font-size: 0.75rem;
  }
}

@media (min-width: 768px) {
  .custom-container {
    flex-direction: row;
  }

  .custom-sidebar {
    flex: 1;
  }

  .logo-vertical {
    display: none; /* Hide vertical logo on desktop */
  }

  .logo-horizontal {
    max-width: 500px; /* Desktop size */
    display: block;
  }

  .custom-form-container {
    flex: 1;
    margin: 20px;
  }

  .footer {
    padding: 12px 20px;
  }
}