/* === Base Layout === */
.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 10px;
}

.scroll-container::-webkit-scrollbar {
  height: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #e4e4e4;
}

.scroll-item {
  display: inline-block;
  vertical-align: top;
}

/* === Cards === */
.fee-card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.3s;
  transform: scale(0.90);
  background: #fff;
  cursor: pointer;
}

.fee-card:hover {
  transform: scale(1.00);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
}

.fee-card.selected {
  transform: scale(1.00);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
  border: 2px solid #4A00E0;
}

.fee-card .btn-icon {
  font-size: 1.5em;
}

.fee-card .action-buttons {
  display: flex;
  align-items: center;
}

.fee-card .action-buttons .btn-link {
  color: inherit;
  text-decoration: none;
}

.fee-card .action-icon-edit {
  color: #6f42c1;
}

.fee-card .action-icon-delete {
  color: #dc3545;
}

.employee-card {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.employee-info {
  display: flex;
  align-items: center;
}

.employee-info .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.employee-info h5 {
  margin: 0;
  font-weight: 600;
}

.employee-info p {
  margin: 0;
  color: #6c757d;
}

.employee-salary h5 {
  font-weight: 700;
}

.employee-actions {
  display: flex;
  align-items: center;
}

.employee-actions .btn-icon {
  color: #007bff;
  text-decoration: none;
  padding-right: 10px;
}

.sum-card {
  display: inline-block;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  color: #4f4d4d !important;
  word-wrap: break-word;
  white-space: normal;
  min-width: 200px;
  box-sizing: border-box;
}

.sum-card.gradient-card {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.sum-card .text div {
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin-bottom: 1rem;
}

.sum-card .text p {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.sum-card.simulation-gradient-card {
  background-color: #F8FAFC;
  border-radius: 10px;
}

.sum-card.simulation-gradient-card .text div {
  color: #4f4d4d;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

.fee-card-insight {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: left;
  width: 180px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fee-card-insight .icon {
  color: #6c757d;
  font-size: 24px;
  margin-right: 10px;
}

.fee-card-insight .text-container {
  display: flex;
  flex-direction: column;
}

.fee-card-insight .title {
  font-size: 14px;
  color: #6c757d;
}

.fee-card-insight .amount {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.simulation-fee-insight {
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.simulation-card-insight {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.simulation-card-insight .amount {
  color: #4A00E0;
  font-size: 18px;
  margin-right: 10px;
}

.simulation-card-insight .text-container {
  display: flex;
  flex-direction: column;
}

.simulation-card-insight .title {
  font-size: 14px;
  color: #6c757d;
}

.simulation-card-insight .spend-amount {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: black;
}

.simulation-card-insight .spend-description {
  color: grey;
  margin-bottom: 1rem;
}

/* === Buttons === */
.add-salary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.view-details-button {
  background-color: #F7F9FB;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: black;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.view-details-button:hover {
  background-color: #E8ECEF;
}

/* === Fee Overview === */
.fee-overview {
  margin-top: 20px;
}

/* === Roles Count === */
.roles-count {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.role-count {
  margin-right: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.role-name {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 1rem;
  color: #555;
}

.count-manager-1 {
  margin-top: 1rem;
  background-color: #fad4d3 !important;
  color: #f85f3c;
}

.count-confirmation-agent {
  margin-top: 1rem;
  background-color: #f2fa55 !important;
  color: #000000;
}

.count-media-buyer {
  margin-top: 1rem;
  background-color: #4e1de2;
  color: #83ace9;
}

.count-packaging-agent {
  margin-top: 1rem;
  background-color: #ebffd0 !important;
  color: #24a321;
}

.count-manager-2 {
  margin-top: 1rem;
  background-color: #4e1de2;
  color: #83ace9;
}

.count-manager-3 {
  margin-top: 1rem;
  background-color: #4e1de2;
  color: #83ace9;
}

.count-other {
  margin-top: 1rem;
  background-color: #e0e1e2 !important;
  color: #353535;
}

/* === Badges === */
.badge {
  font-size: 80%;
  padding: 5px 10px;
  border-radius: 4px;
}