:root {
    /* Colors */
    --color-gray-500: #595959;
    --color-gray-700: #999999;
    --color-gray-900: #d9d9d9;
    --color-danger-300: #a22020;
    --color-danger-500: #bf2626;
    --color-danger-700: #e14747;
    --color-black: #000000;
    --color-white: #ffffff;
    --color-primary-100: #003eb3;
    --color-primary-300: #0074f0;
    --color-primary-500: #14a9ff;
    --color-primary-700: #85dcff;
    --color-success-300: #199033;
    --color-success-500: #32a94c;
    --color-success-700: #4cc366;
    --color-template-blue: #5228f5;
  
    /* Sizes */
    --size-large: 144px;
    --size-small: 48px;
    --size-medium: 96px;
    --size-xlarge: 192px;
    --size-xsmall: 16px;
    --size-xxlarge: 288px;
    --size-maxwidth: 1400px;
  
    /* Radius */
    --radius-round: 50%;
    --radius-small: 2px;
    --radius-medium: 4px;
    --radius-large: 8px;
    --radius-pill: 30px;
    --radius-fullpill: 200px;
  
    /* Spaces */
    --space-unit: 16px;
    --space-halfunit: 8px;
    --space-oneunit: 5px;
    --space-two-units: 32px;
    --space-three-units: 48px;
    --space-four-units: 64px;
    --space-five-units: 80px;
    --space-six-units: 96px;
    --space-seven-units: 112px;
    --space-one-and-half-units: 24px;
  }
  
  :root[data-theme='dark'] {
    --background-color: #151515;
    --text-color: #ffffff;
    --btn-bg-color: #1e1e1e;
    --btn-text-color: #ffffff;
  }
  
  :root[data-theme='light'] {
    --background-color: var(--color-white);
    --text-color: var(--color-black);
    --btn-bg-color: var(--color-primary-500);
    --btn-text-color: #ffffff;
  }