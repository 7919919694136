.dashboard-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: linear-gradient(135deg, #5825be, #4A00E0) !important;
  border-bottom: 1px solid #e8e6e6 !important;
  height: 55px; /* Header height */
  box-shadow: none !important;
}

.navbarCodPricing {
  padding-top: 0;
  padding-bottom: 0;
}

.dashboard-layout .logo {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: 0.75rem;
}

.settings{
  display: flex;
  justify-content: flex-end;
}

.settings-sidebar{
  display: none;
}

@media (max-width: 991.98px) {
  .settings {
    display: none;
  }

  .settings-sidebar {
    display: block;
  }
}

@media (max-width: 767px) {
  .dashboard-layout .logo {
    max-width: 180px; 
  }
}

@media (min-width: 768px) and (max-width: 991px) {
 .dashboard-layout .logo {
    max-width: 180px; 
  }
}

@media (min-width: 992px) {
 .dashboard-layout .logo {
    max-width: 220px; 
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 60px; /* Match header height */
  bottom: 0;
  width: 230px;
  z-index: 999;
  overflow-y: auto;
  background-color: #fafafa !important;
  border-right: 1px solid #e8e6e6;
  transition: all 0.3s ease;
  padding-top: 1rem;
}

.contact-info {
  margin-bottom: 1rem;
}

.emailsupport {
  font-size: 0.9rem;
}

.sidebar-section {
  padding: 0.5rem 1rem;
}

.sidebar-section-header {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}

.sidebar .nav-link {
  color: #333;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar .nav-link:hover {
  background-color: #e8e6e6;
  color: #333;
  border-radius: 8px;
}

.sidebar .nav-link.active {
  background-color: #e8e6e6 !important;
  color: #5228F5 !important;
  border-radius: 8px;
}

.sidebar .nav-link .fa-icon {
  font-size: 1.25rem;
}

.schedule-schedule {
  position: relative;
}

.schedule-icon {
  width: 32px;
  height: 30px;
  margin-bottom: 24px;
}

.schedule-text {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 16px;
}

.schedule-text1 {
  line-height: 24px;
}

@media (max-width: 767px) {
  .schedule-schedule {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
}

.page-content {
  background-color: #fafafa;
  margin-left: 230px;
  margin-top: 65px; /* Match header height */
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem; /* Add some padding for better spacing */
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 70%;
    left: -70%;
    transition: left 0.3s ease;
  }

  .sidebar.open {
    left: 0;
  }

  .page-content {
    margin-left: 0;
    margin-top: 120px; /* Adjust for header (55px) + alert (~30px) on mobile */
  }

  .sidebar .nav-link {
    padding: 1rem 1.5rem;
  }

  .sidebar .nav-link .fa-icon {
    font-size: 1.5rem;
  }

  .backdrop {
    position: fixed;
    top: 60px; /* Match header height */
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 998;
  }

  .backdrop.open {
    opacity: 1;
    visibility: visible;
  }

  .alert-subscription {
    left: 0; /* Full width on mobile */
  }
}

.language-selector {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 1rem;
}

.language-icon {
  font-size: 1.25rem;
  margin-right: 8px;
}

.language-selector-text {
  font-size: 1rem;
  margin-right: 4px;
}

.language-dropdown-menu {
  min-width: 120px;
}

.language-dropdown-item {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.language-dropdown-item .flag-icon {
  margin-right: 8px;
}

.profile-dropdown-toggle {
  margin-left: 1rem;
}

.profile-dropdown-menu {
  left: auto;
  right: 0;
  transform: translateX(-50%);
}

.profile-dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-toggle::after {
  margin-right: .255em;
}

.custom-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 1rem;
}

.custom-dropdown .dropdown-toggle img {
  height: 30px;
}

.custom-dropdown .dropdown-toggle span {
  line-height: 1.5;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

html[dir="rtl"] .sidebar {
  padding-right: 1rem;
  padding-left: 0;
}

html[dir="rtl"] .sidebar-section {
  padding-right: 1rem;
  padding-left: 0.5rem;
}

html[dir="rtl"] .sidebar .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

html[dir="rtl"] .sidebar .nav-link .fa-icon {
  margin-right: 0;
  margin-left: 1rem;
}

html[dir="rtl"] .sidebar-section-header {
  text-align: right;
}

html[dir="rtl"] .schedule-text {
  text-align: right;
}

html[dir="rtl"] .schedule-text1 {
  text-align: right;
}

html[dir="rtl"] .page-content {
  margin-right: 230px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 1rem;
}

html[dir="rtl"] .profile-dropdown-menu {
  left: 0;
  right: auto;
  transform: translateX(-50%);
}

.logo-container {
  text-align: center;
}

.logo-image {
  width: 500px;
  height: auto;
  margin-bottom: 20px;
}

.logo-font h5 {
  font-size: 2rem;
  color: white !important;
  margin: 0px;
  margin-top: 0.2rem;
  margin-left: 0.765rem;
}

@media (max-width: 576px) {
  .logo-image {
    max-width: 300px; 
  }
  .logo-font h1 {
    font-size: 1.25rem;
  }

  .logo-font h5 {
    font-size: 2rem;
  }
}

.sidebar-toggle {
  cursor: pointer;
  font-size: 2rem;
  margin: 0.2rem;
  width: 40px;
  height: 40px;
  color: white;
}

.alert-subscription {
  position: fixed;
  left: 230px; /* Align with page content */
  top:55px;
  right: 0;
  z-index: 998; /* Below sidebar but above content */
  margin: 0; /* Remove margin-top */
  background-color: #ff9d9d; /* Already set above */
  padding: 0px;
}



.alert-subscription {
  position: fixed;
  top: 55px; /* Match header height */
  left: 230px; /* Align with page content, matching sidebar width */
  right: 0;
  z-index: 998; /* Below sidebar (z-index: 999) but above content */
  margin: 0;
  background-color: #ff9d9d;
  padding: 0px;
  width: calc(100% - 230px); /* Full width minus sidebar */
  border-radius: 0; /* Optional: Remove rounded corners for a flat look */
}

/* Responsive adjustments */
@media (max-width: 991.98px) {
  .alert-subscription {
    left: 0; /* Full width on mobile, no sidebar */
    width: 100%; /* Take full width */
    padding: 8px 15px; /* Slightly smaller padding on mobile */
  }
}

/* Adjust page content margin when alert is present */
@media (min-width: 992px) {
  .page-content {
    margin-top: 100px; /* Increase margin-top to account for alert height (55px header + ~30px alert) */
  }
}

/* RTL support */
html[dir="rtl"] .alert-subscription {
  left: 0;
  right: 230px; /* Flip for RTL */
  width: calc(100% - 230px);
}

@media (max-width: 991.98px) {
  html[dir="rtl"] .alert-subscription {
    right: 0; /* Full width on mobile */
    width: 100%;
  }
}

.video-description {
  font-size: 1rem !important;
}

.embed-responsive-16by9 {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.embed-responsive-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.footerSticky {
  background: #f8f9fa;
  border-top: 1px solid #e8e6e6;
  padding: 0.2rem;
  text-align: center;
  width: 100%;
  position: sticky;
  bottom: 0;
  font-size: 0.5rem;
}