.wizard {
  margin: auto;
  padding: 10px;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 0;
}

.step-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: white;
  color: #ccc;
  font-size: 16px;
  font-weight: bold;
  z-index: 2;
  position: relative;
}

.step.active {
  border-color: #4A00E0;
  color: #4A00E0;
}

.step.completed {
  border-color: #28a745;
  color: #28a745;
}

.line {
  position: absolute;
  top: 50%;
  left: 50%; /* Start right at the middle of the step */
  width: calc(100% - 50px); /* Dynamic width to end at the start of the next step */
  height: 2px;
  background-color: #ccc;
  transform: translateX(25%); /* Align the line properly */
  z-index: 1;
}

.line.completed {
  background-color: #28a745;
}

.button-group {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  align-items: center; /* Aligns items vertically (ensure vertical centering if needed) */
}

.button-group .btn {
  margin: 0 10px; /* Adds some space between the buttons */
}

.button-group .btn-next {
  background-color: white !important;
  border-color: #4A00E0;
  color: #4A00E0;
}

.button-group .btn-prev {
  background-color: white !important;
  border-color: #8c8c8c;
  color: #8c8c8c;
}

@media (max-width: 576px) {
  .button-group {
    flex-direction: column;
  }

  .button-group button {
    margin-bottom: 10px;
  }

  .button-group button:last-child {
    margin-bottom: 0;
  }
}