:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;

  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-template-blue-bg: #5228f5ff;
  --dl-space-space-sevenunits: 112px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}

@font-face {
  font-family: 'AstroSpace';
  src: url('./assets/fonts/AstroSpace-0Wl3o.otf') format('opentype'),
       url('./assets/fonts/AstroSpace-eZ2Bg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.logo-font {
  font-family: 'AstroSpace';
  font-style: normal;
  font-size: 4rem;
  font-weight: normal;
  line-height: 64px;
  letter-spacing: 0em;
  text-transform: uppercase;
}

.logo-container {
  text-align: center;
}

.logo-image {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}


.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: translateX(0)!important;
}
.Link {
  cursor: pointer;
  white-space: nowrap;
  margin-right: 40px;
}
.Link:hover {
  color: #5228F5;
}
.card-home {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border: 1px solid var(--dl-color-gray-700);
  border-radius: 30px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: #ffffff;
}
.card-home:hover {
  transform: scale(1.05);
}

.pill {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-unit);
  border-radius: 200px;
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #ffffff;
}
.testimonal {
  width: 100%;
  display: flex;
  max-width: 375px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.accordionContainer {
  gap: 20px;
  fill: rgba(0, 0, 0, 0.5);
  color: var(--dl-color-gray-black);
  width: 100%;
  cursor: pointer;
  display: flex;
  max-width: 800px;
  transition: 0.3s;
  align-items: center;
  user-select: none;
  flex-direction: column;
  justify-content: space-between;
}
.accordionContainer:hover {
  color: rgba(0, 0, 0, 0.5);
}
.metrics {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.title {
  font-size: 56px;
  text-align: center;
  font-weight: 700;
  line-height: 72px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.sub-title {
  color: rgb(82, 40, 245);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.template-button {
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.template-button:hover {
  color: black;
  background-color: #ffffff;
}

.accordionIcon {
  width: 30px;
  height: 30px;
}
.accordionContent {
  width: 100%;
  display: flex;
  user-select: text;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 991px) {
  .testimonal {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .template-button {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .card-home {
    padding-top: var(--dl-space-space-unit);
    margin-right: 0px;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .testimonal {
    max-width: 100%;
  }
  .title {
    font-size: 28px;
    line-height: 36px;
  }
}
@media(max-width: 479px) {
  .card-home {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .pill {
    width: 130px;
    height: 60px;
  }
  .title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .template-button {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    background-color: var(--dl-color-gray-black);
  }
}


.login-button {
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  margin-bottom: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #5228F5;
}

.login-button:hover {
  color: #5228F5;
  border: 2px solid #5228F5;
  background-color: white;
}

/* styles.css */

.container {
  margin-top: 20px;
}

.action-icon {
  margin-left: 10px;
  cursor: pointer;
  transition: color 0.2s;
}

.edit-icon:hover {
  color: blue;
}

.delete-icon:hover {
  color: red;
}


/*plans*/

/* Coupon Form */
.coupon-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  display: inline-block;
}

.coupon-input {
  max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.coupon-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

/* Cards Container */
.cards-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

/* Coupon Form */
.coupon-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.coupon-input {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.coupon-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}



/* Plan Card */
.plan-card {
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.plan-card .card-body {
  padding: 30px;
}

.plan-card .card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #343a40;
}

.plan-card .card-text {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #6c757d;
}

/* Button */
.plan-card .btn-primary {
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.plan-card .btn-primary:hover {
  background-color: #5228F5;
  color: #fff;
}

/* Coupon Form */
.coupon-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the form items */
}

.coupon-input {
  max-width: 300px;
  margin-top: 10px; /* Add some margin-top for better spacing */
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.coupon-form label {
  width: 100%; /* Ensure the label takes the full width */
  text-align: center; /* Center the label text */
}

/* Action Icons */
.action-icon {
  cursor: pointer;
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: #007bff;
}

.delete-icon:hover {
  color: #dc3545;
}

/* Responsive Font Size */
@media (max-width: 768px) {
  .custom-table th,
  .custom-table td {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 576px) {
  .custom-table th,
  .custom-table td {
    font-size: 12px;
    padding: 6px;
  }
}

.btn-primary {
  background: linear-gradient(135deg, #874ff8, #4A00E0) !important;
}

.btn-primary:hover {
  background-color: #7151f4;
  border-color: #7151f4;
}