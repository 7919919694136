.active-users-active-users {
    position: relative;
  }
  .active-users-text {
    color: rgb(255, 255, 255);
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
  }
  .active-users-text1 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    margin-top: 13px;
  }
  @media(max-width: 767px) {
    .active-users-text {
      font-size: 28px;
    }
    .active-users-text1 {
      font-size: 14px;
    }
  }
  @media(max-width: 479px) {
    .active-users-text {
      font-size: 28px;
    }
    .active-users-text1 {
      color: rgb(255, 255, 255);
      font-size: 14px;
    }
  }
  
  .card-card {
    flex: 1;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 40px;
    border-radius: 50px;
    padding-right: 40px;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: flex-start;
    background-color: #ffffff;
  }
  .card-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 23px;
  }
  .card-text01 {
    color: rgb(0, 0, 0);
    line-height: 24px;
    margin-bottom: 67px;
  }
  .card-text03 {
    color: #000000;
  }
  .card-text06 {
    color: #000000;
  }
  .card-get-started {
    color: white;
    cursor: pointer;
    display: flex;
    transition: 0.3s;
    align-items: center;
    padding-top: 18px;
    padding-left: 32px;
    border-radius: 56px;
    margin-bottom: 40px;
    padding-right: 32px;
    flex-direction: column;
    padding-bottom: 18px;
    background-color: #000000;
  }
  .card-get-started:hover {
    color: black;
    background-color: #ffffff;
  }
  .card-text09 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .card-text10 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 23px;
  }
  .card-bullet-points {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .card-point {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex-direction: row;
  }
  .card-icon {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .card-text11 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
  }
  .card-point1 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex-direction: row;
  }
  .card-icon2 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .card-text12 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
  }
  .card-point2 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex-direction: row;
  }
  .card-icon4 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .card-text13 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
  }
  .card-point3 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex-direction: row;
  }
  .card-icon6 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .card-text14 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
  }
  @media(max-width: 991px) {
    .card-card {
      width: 100%;
      align-items: flex-start;
    }
  }  
/* SimulationDetails.css */

/* Custom Table Styling */
.table-custom {
  width: 100%;
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0;
}

.table-custom th,
.table-custom td {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}

.table-custom thead th {
  background-color: #f8f9fa;
  font-weight: bold;
  text-transform: uppercase;
}

.table-custom tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.table-custom tbody tr:last-child td {
  border-bottom: none;
}

/* Custom Tooltip */
.form-label-tooltip {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}
  
  /* FeeVariationList.css */

.table-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .table {
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-color: #dee2e6;
  }
  
  .table th {
    background-color: white;
    color: black;
    text-align: center;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .btn-custom {
    display: inline-flex;
    align-items: center;
    gap: 5px;
  }
  
  .btn-icon {
    border: none;
    padding: 0;
  }
  
  .action-icon-edit {
    color: #0968cd;
    font-size: 1rem;
    cursor: pointer;
    margin: 1rem;
  }


.select-dropdown {
  position: relative;
  display: block;
}

.select-dropdown select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 2.5rem;
  /* Update left padding for RTL support */
  padding-left: 2.5rem;
  background: url('data:image/svg+xml;charset=utf8,<svg fill="%23333" height="14" viewBox="0 0 24 24" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 0.75rem center/1.25rem;
  /* Handle background position for RTL support */
  background-position: right 0.75rem center;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.select-dropdown[dir="rtl"] select {
  padding-left: 75%; /* Adjust based on icon size */
  padding-right: .75rem; /* Swap padding for RTL */
  background-position: left 0.75rem center;
  background-position: left 0.75rem center/1.25rem;
}

.select-dropdown select:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 0.5rem;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  transform: translateY(-50%);
}

.select-dropdown[dir="rtl"] select:after {
  right: unset;
  left: 1rem;
}


  .action-icon-delete {
    color:red;
    font-size: 1rem;
    cursor: pointer;
    margin: 1rem;
  }

  .action-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .edit-icon:hover {
    color: blue;
  }
  
  .delete-icon:hover {
    color: red;
  }
  
  .text-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .testimonal-testimonal {
    position: relative;
  }
  .testimonal-text {
    line-height: 24px;
  }
  .testimonal-author {
    gap: var(--dl-space-space-unit);
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: row;
  }
  .testimonal-avatar {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .testimonal-image {
    width: 40px;
    height: 40px;
    display: flex;
    object-fit: cover;
    border-radius: 50%;
  }
  .testimonal-user {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .testimonal-name {
    color: rgb(0, 0, 0);
    font-style: normal;
    font-weight: 600;
  }
  .testimonal-divider {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .testimonal-from {
    color: rgb(0, 0, 0);
  }
  @media(max-width: 767px) {
    .testimonal-text {
      font-size: 14px;
      line-height: 21px;
    }
    .testimonal-image {
      width: 28px;
      height: 28px;
    }
    .testimonal-name {
      font-size: 14px;
    }
    .testimonal-from {
      font-size: 14px;
    }
  }

  .you-tube-you-tube {
    width: 240px;
    height: 120px;
    display: flex;
    align-items: center;
    border-radius: 200px;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .you-tube-pasted-image {
    width: 120px;
  }
  @media(max-width: 479px) {
    .you-tube-you-tube {
      width: 120px;
      height: 60px;
      align-items: center;
    }
    .you-tube-pasted-image {
      width: 80px;
    }
  }
  
  .home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    overflow-x: hidden;
    flex-direction: column;
  }
  .home-hero {
    width: 100%;
    height: 1000px;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: column;
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-mobile-navigation {
    top: 0px;
    flex: 0 0 auto;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 150;
    position: fixed;
    transform: translateX(100%);
    transition: transform 0.3s;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    background-color: var(--dl-color-gray-white);
  }
  .home-logo {
    width: 64px;
  }
  .home-links {
    gap: var(--dl-space-space-twounits);
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
  }
  .home-close-mobile-menu {
    top: var(--dl-space-space-oneandhalfunits);
    right: var(--dl-space-space-oneandhalfunits);
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-icon {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .home-desktop-navigation {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-oneunit);
    align-items: center;
    flex-direction: column;
  }
  .home-centered {
    width: 100%;
    display: flex;
    max-width: 1200px;
    box-sizing: initial;
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 10px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
  }

  .home-left {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 18px;
    flex-direction: row;
    padding-bottom: 18px;
  }
  .home-logo1 {
    width: 105px;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-links1 {
    display: flex;
    align-items: center;
    margin-left: 33px;
    flex-direction: row;
  }
  .home-right {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 18px;
    flex-direction: row;
    padding-bottom: 18px;
  }
  .home-sign-in {
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    transition: 0.7s;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 10px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    background-color: white;
    color: #5228F5;
    font-style: normal;
    font-style: normal;
    font-weight: 600;
    border: none;
    border: 2px solid #5228F5;
  }
  .home-sign-in:hover {
    color: white;
    border: 2px solid #5228F5;
    background-color: #5228F5;
  }
  .home-get-started {
    cursor: pointer;
    display: flex;
    transition: 0.7s;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 10px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    background-color: #000000;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
  }
  .home-get-started:hover {
    background-color: #ffffff;
    color: #5228F5;
  }
  .home-text006 {
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-mobile-menu-button {
    width: 40px;
    height: 40px;
    display: none;
  }
  .home-container02 {
    display: contents;
  }
  .home-content {
    display: flex;
    margin-top: 60px;
    align-items: center;
    flex-direction: column;
  }
  .home-text007 {
    color: rgb(255, 255, 255);
    font-size: 72px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: 80px;
  }
  .home-text008 {
    color: #EEE9FE;
    font-size: 20px;
    max-width: 600px;
    margin-top: 16px;
    text-align: center;
    line-height: 30px;
  }
  .home-get-started1 {
    color: white;
    cursor: pointer;
    display: flex;
    margin-top: 40px;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    border-radius: 56px;
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
    background-color: #000000;
  }
  .home-get-started1:hover {
    color: black;
    background-color: #ffffff;
  }
  .home-text009 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-dashboard-preview {
    top: 530px;
    display: flex;
    position: absolute;
    align-items: center;
    border-color: #633df6;
    border-width: 30px;
    border-radius: 100px;
    flex-direction: column;
    background-color: #633df6;
  }
  .home-outline {
    display: flex;
    align-items: center;
    border-color: #633df6;
    border-width: 20px;
    border-radius: 70px;
    flex-direction: column;
    background-color: #633df6;
  }
  .home-image {
    width: 900px;
    z-index: 10;
    user-select: none;
  }
  .home-features {
    width: 100%;
    display: flex;
    z-index: 1;
    align-items: center;
    padding-top: 50px;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: column;
    background-color: EFF0F2;
  }
  .home-title {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-text010 {
    font-size: 56px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    line-height: 72px;
  }
  .home-text014 {
    font-size: 20px;
    margin-top: 16px;
    text-align: center;
    line-height: 30px;
  }
  .home-cards {
    width: 100%;
    display: flex;
    max-width: 1200px;
    margin-top: 30px;
    align-items: center;
    flex-direction: column;
  }
  .home-container03 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .home-icon02 {
    width: 32px;
    height: 30px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text017 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text018 {
    line-height: 24px;
  }
  .home-publish {
    height: initial;
  }
  .home-icon03 {
    width: 30px;
    height: 30px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text019 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text020 {
    line-height: 24px;
  }
  .home-container04 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-sixunits);
    flex-direction: row;
  }
  .home-icon04 {
    width: 35px;
    height: 30px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text021 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 16px;
  }
  .home-text022 {
    line-height: 24px;
  }
  .home-icon05 {
    width: 30px;
    height: 30px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text023 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text024 {
    line-height: 24px;
  }
  .home-quote-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 160px;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: column;
    padding-bottom: 160px;
    justify-content: center;
    background-color: #000000;
  }
  .home-quote {
    width: 100%;
    display: flex;
    max-width: 1200px;
    flex-direction: column;
    justify-content: center;
  }
  .home-message {
    color: rgb(255, 255, 255);
    font-size: 40px;
    max-width: 800px;
    line-height: 52px;
  }
  .home-author {
    width: 800px;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    flex-direction: row;
  }
  .home-avatar {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
  }
  .home-quote1 {
    color: rgb(151, 126, 249);
    width: 100%;
    max-width: 800px;
    margin-left: var(--dl-space-space-unit);
  }
  .home-text025 {
    font-style: normal;
    font-weight: 600;
  }
  .home-statistics {
    width: 100%;
    height: 264px;
    display: flex;
    align-items: center;
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: row;
    justify-content: center;
    background-color: #5228F5;
  }
  .home-container05 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-banners {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-banner-manage {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
  }
  .home-container06 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-left1 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .home-text028 {
    text-align: left;
  }
  .home-text029 {
    color: rgb(0, 0, 0);
    line-height: 24px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-get-started2 {
    transition: 0.3s;
    margin-bottom: 0;
  }
  .home-get-started2:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-text030 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-image-container {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-cards-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
  }
  .home-banner-advanced-analytics {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
    background-color: #EFF0F2;
  }
  .home-centered-container {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
  }
  .home-image-container1 {
    flex: 1;
    display: flex;
  }
  .home-cards-image1 {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .home-right1 {
    flex: 1;
    display: flex;
    margin-left: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .home-text032 {
    text-align: left;
  }
  .home-category {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-text033 {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text034 {
    color: rgb(0, 0, 0);
    line-height: 24px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text035 {
    color: #5228F5;
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-weight: 600;
  }
  .home-text035:hover {
    color: #000000;
  }
  .home-category1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text036 {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text037 {
    color: rgb(0, 0, 0);
    line-height: 24px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text038 {
    color: #5228F5;
    cursor: pointer;
    font-style: normal;
    transition: 0.3s;
    font-weight: 600;
  }
  .home-text038:hover {
    color: #000000;
  }
  .home-banner {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
  }
  .home-container07 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-left2 {
    flex: 1;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .home-text039 {
    color: rgb(82, 40, 245);
    font-style: normal;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .home-text040 {
    text-align: left;
  }
  .home-text041 {
    color: rgb(0, 0, 0);
    line-height: 24px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-get-started3 {
    transition: 0.3s;
    margin-bottom: 0;
  }
  .home-get-started3:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-text042 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-image-container2 {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-cards-image2 {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .home-integrations {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-sevenunits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #EFF0F2;
  }
  .home-centered-container1 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-heading {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-text045 {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-pills-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-pills {
    width: 100%;
    display: flex;
    grid-gap: var(--dl-space-space-twounits);
    flex-wrap: wrap;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: row;
    justify-content: center;
  }
  .home-container08 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  .home-container09 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    flex-wrap: wrap;
    margin-left: var(--dl-space-space-fiveunits);
    flex-direction: row;
    justify-content: center;
  }
  .home-how-it-works {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
    background-color: #211062;
  }
  .home-centered-container2 {
    width: 100%;
    height: 100%;
    display: flex;
    max-width: 1200px;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-heading1 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-text046 {
    color: rgb(220, 212, 253);
    font-style: normal;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .home-text047 {
    color: var(--dl-color-gray-white);
  }
  .home-category2 {
    color: white;
    width: 100%;
    display: flex;
    overflow: hidden;
    padding-left: var(--dl-space-space-fourunits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: row;
    background-color: #5228F5;
  }
  .home-headng {
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-fourunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .home-text048 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .home-text049 {
    color: #EEE9FE;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .home-get-started4 {
    margin-bottom: 0px;
  }
  .home-get-started4:hover {
    color: black;
    background-color: #ffffff;
  }
  .home-text050 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-container10 {
    flex: 1;
    height: 100%;
    display: flex;
    align-self: flex-end;
    flex-direction: row;
  }
  .home-pasted-image {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .home-row {
    display: flex;
    grid-gap: var(--dl-space-space-threeunits);
    align-items: center;
    flex-direction: row;
  }
  .home-category3 {
    flex: 1;
    color: white;
    height: 555px;
    display: flex;
    overflow: hidden;
    align-items: flex-end;
    border-radius: 50px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
  }
  .home-headng1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .home-text051 {
    color: rgb(0, 0, 0);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .home-text052 {
    color: #1E1E1E;
    line-height: 24px;
  }
  .home-pasted-image1 {
    width: 100%;
  }
  .home-category4 {
    flex: 1;
    color: white;
    height: 555px;
    display: flex;
    overflow: hidden;
    align-items: flex-end;
    border-radius: 50px;
    flex-direction: column;
    justify-content: space-between;
    background-color: #000000;
  }
  .home-headng2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .home-text053 {
    color: #ffffff;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .home-text054 {
    color: rgb(238, 233, 254);
    line-height: 24px;
  }
  .home-pasted-image2 {
    width: 100%;
    object-fit: contain;
  }
  .home-pricing {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
    background-color: #5228F5;
  }
  .home-centered-container3 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-heading2 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-text055 {
    color: var(--dl-color-gray-white);
    text-align: center;
  }
  .home-text056 {
    color: #EEE9FE;
    width: 600px;
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-selection {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-text057 {
    color: #EEE9FE;
    width: 128px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    padding-top: var(--dl-space-space-oneandhalfunits);
    border-color: rgba(255, 255, 255, 0.3);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-bottom-width: 2px;
  }
  .home-text058 {
    color: #ffffff;
    width: 128px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    padding-top: var(--dl-space-space-oneandhalfunits);
    border-color: #ffffff;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-bottom-width: 2px;
  }
  .home-cards1 {
    width: 100%;
    display: grid;
    grid-gap: var(--dl-space-space-twounits);
    margin-top: var(--dl-space-space-fiveunits);
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .home-card {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-threeunits);
    border-radius: 50px;
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: flex-start;
    background-color: #ffffff;
  }
  .home-text059 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text060 {
    color: rgb(0, 0, 0);
    line-height: 24px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-get-started5 {
    transition: 0.3s;
  }
  .home-get-started5:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-text061 {
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .home-text062 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-bullet-points {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-point {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon06 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text063 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-point01 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon08 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text064 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-card1 {
    flex: 1;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 40px;
    border-radius: 50px;
    padding-right: 40px;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: flex-start;
    background-color: #ffffff;
  }
  .home-text065 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text066 {
    color: rgb(0, 0, 0);
    line-height: 24px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-get-started6 {
    transition: 0.3s;
  }
  .home-get-started6:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-text070 {
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
  }
  .home-text073 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-bullet-points1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-point02 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon10 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text074 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-point03 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon12 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text075 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-point04 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon14 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text076 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-point05 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon16 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text077 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-card2 {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: 40px;
    border-radius: 50px;
    padding-right: 40px;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: flex-start;
    background-color: #000000;
  }
  .home-text078 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text079 {
    color: rgb(255, 255, 255);
    line-height: 24px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-get-started7 {
    color: var(--dl-color-gray-black);
    transition: 0.3s;
    background-color: #ffffff;
  }
  .home-get-started7:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-text082 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-text085 {
    color: #ffffff;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-bullet-points2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-point06 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon18 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text086 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-point07 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon20 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text087 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-point08 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon22 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text088 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-point09 {
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-halfunit);
    flex-direction: row;
  }
  .home-icon24 {
    fill: #5228F5;
    width: 8px;
    height: 8px;
  }
  .home-text089 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-testimonals {
    width: 100%;
    display: flex;
    padding-top: var(--dl-space-space-sixunits);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
  }
  .home-left3 {
    width: 375px;
    display: flex;
    margin-top: 375px;
    align-items: flex-start;
    margin-right: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-text090 {
    color: rgb(82, 40, 245);
    font-style: normal;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .home-text091 {
    text-align: left;
  }
  .home-right2 {
    gap: 40px;
    display: flex;
    flex-direction: row;
  }
  .home-column {
    gap: 40px;
    display: flex;
    flex-direction: column;
  }
  .home-column1 {
    gap: 40px;
    display: flex;
    flex-direction: column;
  }
  .home-text092 {
    cursor: pointer;
    display: none;
  }
  .home-action-bar {
    width: 100%;
    display: flex;
    padding: 120px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-action {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: flex-end;
    border-radius: 50px;
    flex-direction: row;
    justify-content: space-between;
    background-color: #5228F5;
  }
  .home-heading3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-sevenunits);
    padding-left: var(--dl-space-space-sevenunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-text095 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-text099 {
    color: #EEE9FE;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-get-started8 {
    margin-bottom: 0px;
  }
  .home-text100 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-images {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-image1 {
    width: 100%;
    object-fit: cover;
    user-select: none;
  }
  .home-faq {
    width: 100%;
    display: flex;
    padding: 120px;
    align-items: center;
    flex-direction: column;
    padding-bottom: 120px;
    justify-content: center;
    background-color: #EFF0F2;
  }
  .home-heading4 {
    width: 100%;
    display: flex;
    align-items: center;
    user-select: none;
    flex-direction: column;
  }
  .home-text103 {
    text-align: center;
  }
  .home-text104 {
    color: rgb(0, 0, 0);
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-accordion {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-header1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .home-text105 {
    width: 100%;
    font-size: 20px;
    max-width: 760px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text106 {
    width: 100%;
    max-width: 760px;
    line-height: 24px;
  }
  .home-header2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .home-text107 {
    width: 100%;
    font-size: 20px;
    max-width: 760px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text108 {
    width: 100%;
    max-width: 760px;
    line-height: 24px;
  }
  .home-header3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .home-text109 {
    width: 100%;
    font-size: 20px;
    max-width: 760px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text110 {
    width: 100%;
    max-width: 760px;
    line-height: 24px;
  }
  .home-header4 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .home-text111 {
    width: 100%;
    font-size: 20px;
    max-width: 760px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text112 {
    width: 100%;
    max-width: 760px;
    line-height: 24px;
  }
  .home-header5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .home-text113 {
    width: 100%;
    font-size: 20px;
    max-width: 760px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text114 {
    width: 100%;
    max-width: 760px;
    line-height: 24px;
  }
  .home-container12 {
    display: contents;
  }
  .home-footer {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-sevenunits);
    padding-left: var(--dl-space-space-sevenunits);
    padding-right: var(--dl-space-space-sevenunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sevenunits);
    background-color: #000000;
  }
  .home-top {
    width: 100%;
    display: flex;
    max-width: 1200px;
    border-color: #3B3B3B;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: space-between;
    border-bottom-width: 1px;
  }
  .home-left4 {
    width: 33%;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-text115 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-subscription {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    border-radius: 50px;
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
    background-color: #ffffff;
  }
  .home-textinput {
    width: 60%;
    outline: none;
    font-size: 14px;
    border-width: 0px;
  }
  .home-subscribe {
    cursor: pointer;
    display: flex;
    transition: 0.3s;
    align-items: center;
    padding-top: 16px;
    padding-left: 32px;
    border-radius: 56px;
    padding-right: 32px;
    flex-direction: row;
    padding-bottom: 16px;
    justify-content: flex-start;
    background-color: #5228F5;
  }
  .home-subscribe:hover {
    background-color: #000;
  }
  .home-text116 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  .home-text117 {
    color: #686868;
    font-size: 14px;
  }
  .home-contact {
    display: flex;
    margin-top: 52px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text118 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link {
    color: #C4C4C4;
    font-size: 14px;
    text-decoration: underline;
  }
  .home-right3 {
    gap: var(--dl-space-space-fiveunits);
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-threeunits);
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-category5 {
    width: 240px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  .home-text119 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-links2 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text120 {
    color: rgb(196, 196, 196);
    cursor: pointer;
    font-size: 14px;
  }
  .home-text121 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text122 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text123 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text124 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-category6 {
    width: 240px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text125 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-links3 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text126 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text127 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text128 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text129 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text130 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text131 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-category7 {
    width: 240px;
    display: flex;
    flex-direction: column;
  }
  .home-text132 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-links4 {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text133 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text134 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text135 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text136 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-text137 {
    color: rgb(196, 196, 196);
    font-size: 14px;
  }
  .home-bottom {
    width: 100%;
    display: flex;
    max-width: 1200px;
    margin-top: var(--dl-space-space-threeunits);
    align-items: flex-start;
    user-select: none;
    flex-direction: column;
  }
  .home-branding {
    width: var(--dl-size-size-large);
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text138 {
    color: #686868;
    font-size: 14px;
    line-height: 30px;
  }
  @media(max-width: 991px) {
    .home-hero {
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .home-close-mobile-menu {
      align-items: center;
      justify-content: center;
    }
    .home-desktop-navigation {
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .home-centered {
      height: 60px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .home-left {
      margin-left: var(--dl-space-space-halfunit);
    }
    .home-logo1 {
      width: 64px;
    }
    .home-links1 {
      display: none;
    }
    .home-sign-in {
      display: none;
    }
    .home-get-started {
      display: none;
    }
    .home-mobile-menu-button {
      display: flex;
    }
    .home-content {
      width: 100%;
      max-width: 1200px;
    }
    .home-image {
      width: 100%;
    }
    .home-features {
      padding-top: 50px;
    }
    .home-title {
      width: 100%;
      max-width: 1200px;
    }
    .home-text010 {
      font-size: 36px;
      line-height: 40px;
    }
    .home-text014 {
      font-size: 20px;
      margin-top: var(--dl-space-space-unit);
      line-height: 30px;
    }
    .home-cards {
      margin-top: var(--dl-space-space-fiveunits);
    }
    .home-text018 {
      line-height: 21px;
    }
    .home-text020 {
      line-height: 21px;
    }
    .home-text022 {
      line-height: 21px;
    }
    .home-text023 {
      font-size: 24px;
    }
    .home-text024 {
      line-height: 21px;
    }
    .home-quote-container {
      padding-top: var(--dl-space-space-sevenunits);
      padding-bottom: var(--dl-space-space-sevenunits);
    }
    .home-message {
      width: 100%;
      max-width: 800px;
    }
    .home-author {
      width: 100%;
      max-width: 800px;
    }
    .home-statistics {
      height: 800px;
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-container05 {
      height: 100%;
      flex-direction: column;
    }
    .home-banner-manage {
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-left1 {
      margin-right: var(--dl-space-space-threeunits);
    }
    .home-cards-image {
      width: 360px;
    }
    .home-banner-advanced-analytics {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-container09 {
      margin-left: 0px;
    }
    .home-category2 {
      padding-left: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
      flex-direction: column;
    }
    .home-headng {
      margin-left: var(--dl-space-space-fourunits);
    }
    .home-container10 {
      width: 100%;
    }
    .home-pasted-image {
      height: auto;
    }
    .home-headng1 {
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-fourunits);
      padding-right: var(--dl-space-space-fourunits);
    }
    .home-headng2 {
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-fourunits);
      padding-right: var(--dl-space-space-fourunits);
    }
    .home-text056 {
      width: 100%;
      max-width: 600px;
    }
    .home-cards1 {
      display: flex;
      flex-direction: column;
    }
    .home-card {
      width: 100%;
      align-items: flex-start;
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-text060 {
      font-size: 16px;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-text062 {
      font-size: 16px;
    }
    .home-card1 {
      width: 100%;
      align-items: flex-start;
    }
    .home-card2 {
      width: 100%;
      align-items: flex-start;
    }
    .home-testimonals {
      align-items: center;
      flex-direction: column;
    }
    .home-left3 {
      margin-top: 0px;
      align-items: center;
      margin-bottom: ThreeUnits;
    }
    .home-text091 {
      font-size: 36px;
      text-align: center;
      line-height: 40px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-right2 {
      gap: var(--dl-space-space-oneandhalfunits);
      flex-direction: row;
    }
    .home-column {
      gap: var(--dl-space-space-oneandhalfunits);
    }
    .home-column1 {
      gap: var(--dl-space-space-oneandhalfunits);
    }
    .home-action {
      flex-direction: column;
    }
    .home-heading3 {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-threeunits);
      padding-right: var(--dl-space-space-threeunits);
      padding-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-text095 {
      font-size: 36px;
      line-height: 36px;
    }
    .home-accordion {
      width: 100%;
      max-width: 1200px;
    }
    .home-footer {
      padding: var(--dl-space-space-sixunits);
    }
    .home-top {
      flex-direction: column;
    }
    .home-left4 {
      width: 100%;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-text117 {
      color: rgb(104, 104, 104);
      font-size: 14px;
    }
    .home-text118 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-link {
      text-decoration: underline none;
    }
    .home-right3 {
      gap: var(--dl-space-space-twounits);
      flex-direction: column;
    }
    .home-links2 {
      gap: var(--dl-space-space-unit);
    }
    .home-links3 {
      gap: var(--dl-space-space-unit);
    }
    .home-links4 {
      gap: var(--dl-space-space-unit);
    }
    .home-branding {
      width: var(--dl-size-size-medium);
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .home-text138 {
      font-size: 12px;
      line-height: 25px;
      margin-bottom: 0px;
    }
  }
  @media(max-width: 767px) {
    .home-hero {
      height: 620px;
      position: relative;
      padding-bottom: 180px;
      background-color: var(--dl-color-template-blue-bg);
    }
    .home-desktop-navigation {
      padding-left: 0px;
      padding-right: 0px;
    }
    .home-centered {
      padding-left: 0px;
      padding-right: 0px;
    }
    .home-left {
      margin-left: var(--dl-space-space-unit);
    }
    .home-right {
      margin-right: var(--dl-space-space-unit);
    }
    .home-sign-in {
      display: none;
    }
    .home-get-started {
      display: none;
    }
    .home-content {
      height: 100%;
      margin-top: var(--dl-space-space-fourunits);
      justify-content: center;
    }
    .home-text007 {
      color: rgb(255, 255, 255);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
    .home-text008 {
      color: rgb(238, 233, 254);
      font-size: 16px;
      margin-top: var(--dl-space-space-unit);
      line-height: 24px;
    }
    .home-get-started1 {
      margin-top: var(--dl-space-space-twounits);
    }
    .home-dashboard-preview {
      top: 420px;
      width: 90%;
      border-width: 10px;
      border-radius: 45px;
    }
    .home-outline {
      border-width: 10px;
      border-radius: 35px;
    }
    .home-image {
      width: 100%;
    }
    .home-features {
      padding-top: 50px;
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .home-text010 {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
    .home-container03 {
      gap: var(--dl-space-space-oneandhalfunits);
      margin-bottom: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .home-text017 {
      font-size: 24px;
    }
    .home-text018 {
      font-size: 14px;
    }
    .home-publish {
      margin-left: 0px;
    }
    .home-text019 {
      font-size: 24px;
    }
    .home-text020 {
      font-size: 14px;
    }
    .home-container04 {
      gap: var(--dl-space-space-oneandhalfunits);
      flex-direction: column;
    }
    .home-analyze {
      margin-right: 0px;
    }
    .home-text021 {
      font-size: 24px;
    }
    .home-text022 {
      font-size: 14px;
    }
    .home-text024 {
      font-size: 14px;
    }
    .home-quote-container {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-message {
      font-size: 24px;
      line-height: 31px;
    }
    .home-author {
      width: 100%;
    }
    .home-avatar {
      width: 40px;
      height: 40px;
    }
    .home-quote1 {
      font-size: 14px;
      max-width: 800px;
    }
    .home-statistics {
      height: 100%;
    }
    .home-container05 {
      gap: var(--dl-space-space-fourunits);
      justify-content: center;
    }
    .home-banner-manage {
      padding-top: var(--dl-space-space-fourunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-container06 {
      flex-direction: column;
    }
    .home-left1 {
      margin-right: 0px;
    }
    .home-text028 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text029 {
      line-height: 24px;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-text030 {
      font-size: 16px;
    }
    .home-image-container {
      justify-content: center;
    }
    .home-cards-image {
      margin-top: var(--dl-space-space-threeunits);
    }
    .home-banner-advanced-analytics {
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-centered-container {
      flex-direction: column-reverse;
    }
    .home-right1 {
      margin-left: 0px;
    }
    .home-text032 {
      font-size: 28px;
      text-align: left;
      line-height: 26px;
      margin-bottom: var(--dl-space-space-twounits);
    }
    .home-text033 {
      font-size: 20px;
    }
    .home-text034 {
      line-height: 24px;
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-text036 {
      font-size: 20px;
    }
    .home-text037 {
      line-height: 24px;
      margin-bottom: var(--dl-space-space-unit);
    }
    .home-banner {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-container07 {
      flex-direction: column;
    }
    .home-left2 {
      margin-right: 0px;
    }
    .home-integrations {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-text045 {
      line-height: 24px;
    }
    .home-pills {
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-how-it-works {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-heading1 {
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-text047 {
      margin-bottom: 0px;
    }
    .home-headng {
      padding-bottom: 0px;
    }
    .home-get-started4 {
      display: none;
    }
    .home-container10 {
      width: 100%;
    }
    .home-row {
      height: auto;
      flex-direction: column;
    }
    .home-pricing {
      width: 100%;
      padding-left: var(--dl-space-space-fourunits);
      padding-right: var(--dl-space-space-fourunits);
    }
    .home-card {
      padding-bottom: var(--dl-space-space-twounits);
    }
    .home-text059 {
      font-size: 28px;
    }
    .home-card1 {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-text065 {
      font-size: 28px;
    }
    .home-card2 {
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
      padding-bottom: var(--dl-space-space-threeunits);
    }
    .home-text078 {
      font-size: 28px;
    }
    .home-testimonals {
      padding-left: var(--dl-space-space-fourunits);
      padding-right: var(--dl-space-space-fourunits);
      padding-bottom: 0px;
    }
    .home-right2 {
      width: 100%;
      flex-direction: column;
    }
    .home-column1 {
      display: none;
    }
    .home-text092 {
      display: flex;
      font-style: normal;
      margin-top: var(--dl-space-space-oneandhalfunits);
      font-weight: 600;
    }
    .home-action-bar {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-fourunits);
      padding-right: var(--dl-space-space-fourunits);
      padding-bottom: var(--dl-space-space-fourunits);
    }
    .home-action {
      align-items: center;
    }
    .home-heading3 {
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .home-text095 {
      font-size: 28px;
      text-align: left;
    }
    .home-text100 {
      color: var(--dl-color-gray-white);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }
    .home-images {
      justify-content: center;
    }
    .home-image1 {
      width: 100%;
    }
    .home-faq {
      padding: var(--dl-space-space-fourunits);
    }
    .home-heading4 {
      width: 100%;
    }
    .home-text103 {
      font-size: 36px;
      line-height: 36px;
    }
    .home-text104 {
      width: 100%;
      max-width: 600p;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-footer {
      padding-top: var(--dl-space-space-fourunits);
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
  }
  @media(max-width: 479px) {
    .home-hero {
      padding-bottom: 160px;
    }
    .home-logo {
      margin-left: 0px;
    }
    .home-icon {
      align-items: center;
      justify-content: center;
    }
    .home-text008 {
      color: #EEE9FE;
    }
    .home-get-started1 {
      width: 100%;
    }
    .home-dashboard-preview {
      top: 470px;
    }
    .home-features {
      padding-top: 50px;
    }
    .home-cards {
      margin-top: var(--dl-space-space-threeunits);
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .home-container03 {
      gap: var(--dl-space-space-oneandhalfunits);
      margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
    .home-container04 {
      gap: var(--dl-space-space-oneandhalfunits);
      margin-bottom: 0px;
    }
    .home-analyze {
      margin-bottom: 0px;
    }
    .home-statistics {
      height: 500px;
    }
    .home-text029 {
      line-height: 24px;
    }
    .home-cards-image {
      width: 100%;
      margin-top: var(--dl-space-space-twounits);
    }
    .home-category2 {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
      flex-direction: column;
    }
    .home-pricing {
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .home-get-started7 {
      background-color: var(--dl-color-gray-white);
    }
    .home-testimonals {
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .home-action-bar {
      padding-left: var(--dl-space-space-oneandhalfunits);
      padding-right: var(--dl-space-space-oneandhalfunits);
    }
    .home-footer {
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-subscription {
      padding-top: var(--dl-space-space-halfunit);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-halfunit);
      padding-bottom: var(--dl-space-space-halfunit);
    }
  }
  
  .not-found-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .not-found-container1 {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .not-found-text1 {
    color: rgb(38, 38, 38);
    font-size: 252px;
    margin-top: -20px;
    font-weight: 900;
    margin-bottom: -20px;
    letter-spacing: -20px;
  }
  .not-found-container2 {
    width: 421px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .not-found-text2 {
    text-align: center;
    font-weight: 400;
  }
  