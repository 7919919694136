.toast-container {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 1060;
  }
  
  .toast-success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .toast-error {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .toast-body .close {
    border: none;
    background: none;
    font-size: 1.2rem;
    line-height: 1;
    color: #000;
    opacity: 0.7;
  }
  
  .toast-body .close span {
    display: inline-block;
    padding: 0;
    cursor: pointer;
  }
  
  .toast-body .close:hover {
    color: #000;
    text-decoration: none;
    opacity: 1;
  }