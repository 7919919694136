/* Container for plans */
.plans-container {
  padding-top: 40px;
  margin-top: 40px;
  padding: 0 5px;
}

/* Plan card styling */
.plan-card {
  border: none;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  margin: 1%; /* Ensure margin between cards */
  max-width: 100%;
  padding: 0px !important;
}

.plan-card:hover {
  transform: scale(1.05);
}

.plan-card-0 {
  background: linear-gradient(145deg, #e6e9f0, #eef1f5);
}

.plan-card-1 {
  border: 2px solid  #4A00E0;
  height: 500px;
}

.plan-title {
  font-size: 2rem;
  font-weight: bold;
}

.plan-price {
  font-size: 1.8rem;
  font-weight: bold;
}

.plan-savings {
  background-color: rgb(94, 174, 94) !important;
  color: #fff;
  font-size: 1rem;
  margin-right: 10px;
  margin-left: 10px;
}

.plan-details {
  margin: 20px 0;
  font-size: 1.2rem;
  text-align: center;
}

.plan-details p {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.check-icon-monthly {
  color: green;
  background-color: white;
  margin: 0 5px;
  border-radius: 50%;
}

.check-icon-yearly {
  color: white;
  background-color: rgb(94, 174, 94);
  margin: 0 5px;
  border-radius: 50%;
}

.plan-btn {
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 30px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.plan-contact {
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
}

.coupon-form .coupon-input.is-valid {
  border-color: green;
}

.coupon-form .coupon-input.is-invalid {
  border-color: red;
}

.discounted-price {
  color: rgb(94, 174, 94);
  font-size: 2rem;
}

/* Responsive Adjustments */

/* Large devices (desktops) */
@media (min-width: 1200px) {
  .plan-card {
    flex: 1 1 auto;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 1199px) {
  .plan-card {
    flex: 1 1 auto;
  }
}

/* Small devices (phones) */
@media (max-width: 767px) {
  .plan-card {
    flex: 1 1 auto; /* Ensure all cards fit in one row */
    margin: 0 1%; /* Side margins to center the card */
  }

  .plan-title {
    font-size: 1.5rem;
  }

  .plan-price {
    font-size: 1.6rem;
  }
}


.popular {
  background-color: #4A00E0 !important;
  padding: 10px 10px;}