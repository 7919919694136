/* Comparison.css */
.comparison-container {
  margin-top: 20px;
}

.comparison-container h2 {
  margin-bottom: 20px;
}

.comparison-container .form-group {
  margin-bottom: 20px;
}

.nav-tabs {
  margin-top: 20px;
}

.nav-tabs .nav-item .nav-link {
  font-weight: bold;
}

.empty-selection {
  text-align: center;
  margin-top: 50px;
}

.empty-selection .empty-image {
  max-width: 200px;
  margin-bottom: 20px;
}

.empty-selection .empty-text {
  font-size: 1.2rem;
  color: gray;
}

.custom-table-footer {
  margin-top: 20px;
  border-top: 2px solid #dee2e6;
  background-color: #f8f9fa;
  font-weight: bold;
}
