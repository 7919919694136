.profile-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .avatar-container {
    display: inline-block;
    margin: 1rem;
    cursor: pointer;
    align-items: center;
  }
  
  .avatar-img {
    width: 100px;
    height: 100px;
    border-radius: 10%;
    transition: border 0.3s, box-shadow 0.3s;
  }
  
  .avatar-img:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .avatar-img.selected {
    border: 3px solid #4a00e0;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  }