.employee-card {
    display: flex;
    align-items: left;
  }
  
  .avatar-placeholder {
    background-color: #e0e0e0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  
  .info {
    margin-left: 10px;
  }
  
  .btn-icon .action-icon-delete {
    color: red;
  }
  
  .btn-custom {
    border-radius: 50px;
  }


  .custom-badge-variation {
    display: inline-flex;
    align-items: center;
    background-color: #e0e0e0; /* Example background color */
    padding: 0.5em 1em;
    border-radius: 0.25em;
  }
  
  .custom-badge-variation-confirmed {
    background-color: #4caf50; /* Example confirmed background color */
    color: #fff;
  }
  
  .custom-badge-variation .me-2 {
    margin-right: 0.5em; /* Space between icon and text */
  }
  
  .custom-badge-variation .fa-users {
    font-size: 1.2em; /* Adjust size as needed */
  }
  