/* === Base Layout === */
.simulations-container {
  padding: 1.5rem;
  background-color: #f7f8fc;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

.simulation-details-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.search-filter-container {
  background: #fff;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.preview {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.fixed-preview {
  position: static;
  padding-left: 15px;
}

.scroll-content {
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
}

.simulations-table-container {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

/* === Typography === */
h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.section-title-custom {
  margin-bottom: 10px;
  font-weight: bold;
}

.section-description {
  margin-bottom: 1rem;
}

.preview h6 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #444;
}

.total-profit {
  font-weight: bold;
  color: #333;
}

.total-amount {
  font-weight: bold;
}

.filters-title {
  font-weight: 500;
  font-size: 0.9rem;
  color: #6c757d;
}

.loading-text {
  font-size: 1.2rem;
  color: #4A00E0;
}

/* === Buttons === */
.new-simulation-button {
  background: linear-gradient(135deg, #4A00E0, #6f42c1);
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  transition: transform 0.2s, background 0.3s;
}

.new-simulation-button:hover {
  background: linear-gradient(135deg, #370777, #593c9b);
  transform: translateY(-2px);
}

.btn-primary {
  background: #4A00E0;
  border: none;
  border-radius: 8px !important;
}

.btn-primary:hover {
  background: #cca6f3;
  border: none;
}

.btn-secondary {
  background: #ffffff;
  color: #4A00E0;
  cursor: pointer;
  border: none;
}

.btn-secondary:hover {
  background: #eeeeee;
  color: #370777;
  border: none;
}

.btn-new {
  margin-left: 15px;
}

.add-row-button {
  text-decoration: none;
  color: #6c757d;
  display: block;
  margin-top: 0.5rem;
}

.add-row-button:hover {
  color: #343a40;
}

.filter-toggle-btn {
  color: #4A00E0;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.clear-filters {
  font-size: 0.85rem;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

/* === Cards === */
.product-card {
  background: #f0eaff;
}

.product-card .fee-overview .simulation-card-insight {
  background-color: rgba(255, 255, 255, 0.1);
}

.simulation-card-insight {
  background: #fff;
  border-radius: 8px;
  padding: 0.75rem;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.simulation-card-insight .amount {
  font-weight: 600;
  color: #4A00E0;
}

.card-simulation {
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.card-simulation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-simulation-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-simulation-body {
  display: flex;
  justify-content: space-around;
}

.card-simulation-body div {
  text-align: center;
}

.card-simulation-icon {
  font-size: 24px;
  color: purple;
}

.card-simulation-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.card-simulation-footer .btn {
  background: transparent;
  border: none;
  color: red;
}

.card-simulation-footer .btn:hover {
  color: darkred;
  background: transparent;
  border: none;
}

.card-custom {
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
  margin-top: 20px;
  font-size: 0.8rem;
}

.card-custom .card-body {
  padding: 10px;
  background-color: #fff;
}

.custom-card {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.custom-card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  padding: 0.75rem;
}

.custom-card .card-header h6 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

.card-card {
  flex: 1;
  display: flex;
  align-items: flex-start;
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
  background-color: #ffffff;
}

.card-card h3 {
  color: #333;
  margin-bottom: 20px;
}

.card-get-started {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 15px;
  background-color: #000000;
  text-align: center;
  transition: 0.3s;
}

.card-get-started:hover {
  color: #000000;
  background-color: #ffffff;
}

.card-bullet-points {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.card-point {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.card-icon {
  fill: #5228F5;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.card-text11 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

/* === Tables === */
.custom-table {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table thead th {
  background: #4A00E0;
  color: #fff;
  padding: 0.5rem;
  text-align: left;
  font-weight: 500;
}

.custom-table td {
  padding: 0.75rem 1rem;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
}

.custom-table .td-label {
  color: #4A00E0;
  font-weight: 600;
}

.custom-table th,
.custom-table td {
  vertical-align: middle;
  border-top: none;
}

.custom-table thead th {
  background-color: #fafafa;
  border-bottom: 2px solid #dee2e6;
  color: black;
}

.custom-table tbody tr:hover {
  background-color: #f1f3f5;
}

.custom-table thead th:last-child,
.custom-table tbody td:last-child {
  text-align: right;
}

.total-amount-positive {
  color: #28A745;
  font-weight: 600;
}

.total-amount-negative {
  color: #DC3545;
  font-weight: 600;
}

.employee-variant-table {
  margin-bottom: 20px;
  text-align: left;
  font-size: smaller;
}

.employee-variant-table thead {
  background-color: #f8f9fa;
}

.employee-variant-table thead th {
  font-weight: bold;
  text-align: left;
}

.employee-variant-table tbody td {
  vertical-align: middle;
  text-align: left;
}

.employee-variant-table .form-control-custom {
  width: 100%;
}

.table-custom {
  width: 100%;
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0;
}

.table-custom th,
.table-custom td {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
}

.table-custom thead th {
  background-color: #f8f9fa;
  font-weight: bold;
  text-transform: uppercase;
}

.table-custom tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.table-custom tbody tr:last-child td {
  border-bottom: none;
}

.table-responsive td {
  padding: 0.25rem;
  vertical-align: middle;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

/* === Tabs === */
.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  border-radius: 8px 8px 0 0;
  padding: 0.75rem 1.5rem;
  color: #6C757D;
  text-decoration: none;
}

.nav-tabs .nav-link.active {
  background: #fff;
  color: #4A00E0;
  border-bottom: none;
}

.nav-tabs .nav-link:hover {
  background: #4A00E0;
  color: #fff;
}

.nav-link {
  text-decoration: none;
  color: #333;
}

.tab-content {
  border-top: 1px solid #4A00E0;
}

/* === Modals === */
.modal-dialog {
  max-width: 700px;
}

.modal-content {
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.modal {
  padding: 2rem;
}

.modal-header {
  border-bottom: none;
}

.stepper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.step-container {
  position: relative;
  display: flex;
  align-items: center;
}

.step {
  width: 2rem;
  height: 2rem;
  background: #e9ecef;
  color: #6C757D;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  transition: background 0.3s, color 0.3s;
}

.step.active {
  background: #4A00E0;
  color: #fff;
}

.step.completed {
  background: #28A745;
  color: #fff;
}

.line {
  width: 3rem;
  height: 2px;
  background: #e9ecef;
  margin: 0 0.5rem;
}

.line.completed {
  background: #4A00E0;
}

/* === Forms === */
.form-group-custom {
  margin-bottom: 1.25rem;
}

.form-control-custom {
  border-radius: 8px;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control-custom:focus {
  border-color: #4A00E0;
  box-shadow: 0 0 0 0.2rem rgba(74, 0, 224, 0.25);
}

.form-control-custom:disabled {
  background-color: #e9ecef !important;
}

.form-control {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.form-control:disabled {
  background-color: white;
}

.form-control.is-invalid {
  border-color: #dc3545;
}

.form-custom {
  padding: 0.5rem;
}

.form-label {
  margin-bottom: .25rem;
}

.form-select {
  border-radius: 0.25rem;
}

.custom-select {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOCBMIDkgMTIgTCAxMiA4IiBzdHJva2U9IiMwMDU2QjMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat right 10px center;
}

.custom-select-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOCBMIDkgMTIgTCAxMiA8IiBzdHJva2U9IiMwMDU2QjMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat right 10px center;
  padding-right: 30px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  font-size: small;
  width: min-content !important;
}

.custom-select-arrow:focus {
  border-color: #4A00E0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(123, 0, 255, 0.25);
}

.slider-custom .rc-slider-track {
  background: #4A00E0;
}

.slider-custom .rc-slider-handle {
  border-color: #4A00E0;
  background: #fff;
}

.slider-custom {
  margin-top: 0.2rem;
}

.rc-slider {
  padding: 0px !important;
}

.rc-slider-track {
  background-color: #4A00E0 !important;
  border-radius: 5px;
}

.rc-slider-handle {
  border-color: #4A00E0 !important;
  border-radius: 5px;
  background-color: #4A00E0 !important;
}

.form-text-custom {
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  color: #495057;
  font-size: 0.9rem;
}

.input-group-text {
  background-color: white;
  color: #4A00E0;
}

.coupon-input.is-valid {
  border-color: #28a745;
}

.coupon-input.is-invalid {
  border-color: #dc3545;
}

/* === Filters === */
.filter-pill {
  background: #e9ecef;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.filter-pill .close-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  color: #6C757D;
}

.filter-pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-select {
  min-width: 150px;
}

/* === Badges === */
.custom-badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  color: #4e00b3;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 1rem;
  border: 1px solid #ffffff;
  background-color: #e7f1ff;
}

.custom-badge-confirmed {
  color: #0056b3;
  background-color: #e7f1ff;
  border: 1px solid #0056b3;
}

.custom-badge-delivered {
  color: #28a745;
  background-color: #e7fbf5;
  border-radius: 12px;
  padding: 0.2em 0.5em;
  border: 1px solid #28a745;
  font-weight: 500;
}

.custom-badge-daily {
  color: #2f7a2d;
  background-color: #e2f4e9;
  border: none;
  border-radius: 12px;
  padding: 0.2em 0.5em;
  font-weight: 600;
}

.custom-badge-monthly {
  color: #8A63DF;
  background-color: #F5F3FF;
  border-radius: 12px;
  border: none;
  padding: 0.2em 0.5em;
  font-weight: 600;
}

.custom-badge-yearly {
  color: #065aad;
  background-color: #d6eafe;
  border: none;
  border-radius: 12px;
  padding: 0.2em 0.5em;
  font-weight: 600;
}

.custom-badge-quarterly {
  color: #E3A113;
  background-color: #FEFBEA;
  border-radius: 12px;
  padding: 0.2em 0.5em;
  font-weight: 600;
}

.custom-badge i {
  margin-right: 0.25em;
}

.badge-bg-test {
  background-color: #9c9c9c;
}

.badge-bg-product {
  background-color: #4A00E0;
}

/* === Miscellaneous === */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
  width: 100%;
}

.footer-table {
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
  width: 100%;
}

.sidebar {
  padding-top: 1rem;
  background-color: #f8f9fa;
}

.sidebar .nav-link:hover {
  background-color: #e9ecef;
  color: #5228F5;
}

.sidebar .nav-link.active {
  background-color: #5228F5;
  color: #fff;
}

.edit-icon {
  color: #007bff;
}

.delete-icon {
  color: #dc3545;
}

.action {
  text-align: center !important;
}

.action-icons {
  margin-right: 0.25rem;
  cursor: pointer;
  padding: 1rem;
  white-space: nowrap;
  text-align: center !important;
}

.name {
  text-align: center !important;
}

.name-th {
  text-align: center !important;
}

.th-label-simulation {
  text-align: left !important;
}

.amount-section {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.separator {
  margin: 0 10px;
  color: gray;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 8px;
}

.discounted-price {
  color: #d9534f;
  font-weight: bold;
}

.savings {
  color: #28a745;
}

.custom-tooltip .tooltip-inner {
  max-width: 400px;
  width: auto;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  white-space: pre-line;
  word-wrap: break-word;
}

.loading-spinner {
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
  color: #4A00E0;
}

/* === Responsive Adjustments === */
@media (max-width: 991px) {
  .simulations-container {
    padding: 1rem;
  }
  .fixed-preview {
    position: static;
    padding-left: 0;
    margin-top: 1.5rem;
    width: 100%;
  }
  .scroll-content {
    flex-wrap: wrap;
    justify-content: center;
  }
  .sum-card {
    flex: 1 1 45%;
    max-width: 200px;
  }
  .simulation-preview-col {
    position: static;
    margin-top: 1.5rem;
  }
  .modal-dialog {
    max-width: 90%;
  }
  .card-card {
    width: 100%;
  }
  .simulation-preview-col {
    position: static;
    padding-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .active-users-text {
    font-size: 28px;
  }
  .active-users-text1 {
    font-size: 14px;
  }
  .schedule-schedule {
    margin-right: 0px;
  }
  .fixed-preview {
    position: static;
    padding-left: 0;
    margin-top: 1.5rem;
    width: 100%;
  }
  .scroll-content {
    flex-direction: column;
    align-items: center;
  }
  .sum-card {
    flex: 1 1 100%;
    max-width: 300px;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }
  .new-simulation-button {
    padding: 0.5rem 1rem;
  }
  .custom-table td {
    font-size: 0.875rem;
  }
  .stepper {
    flex-wrap: wrap;
  }
  .step-container {
    margin-bottom: 0.5rem;
  }
  .sum-card {
    min-width: 100%;
  }
  .simulation-details-container {
    padding: 1rem;
  }
  .section-title {
    font-size: 1.25rem;
  }
  .form-group-custom {
    margin-bottom: 1rem;
  }
  .form-text-custom {
    font-size: 0.75rem;
  }
  .total-profit {
    font-size: 1rem;
  }
  .table-custom {
    font-size: 0.875rem;
  }
  .table-custom .td-label {
    font-weight: 500;
  }
  .total-amount-positive,
  .total-amount-negative {
    font-size: 1rem;
  }
  .custom-card .card-header {
    font-size: 1rem;
  }
  .custom-card .card-body {
    padding: 1rem;
  }
}

@media (min-width: 768px) {
  .search-filter-container .collapse {
    display: block !important;
  }
  .filter-toggle-btn {
    display: none !important;
  }
}

/* === RTL Adjustments === */
html[dir="rtl"] .custom-table {
  direction: rtl;
  text-align: right;
}

html[dir="rtl"] .employee-variant-table tbody td {
  text-align: right;
}

html[dir="rtl"] .custom-table .td-label {
  border: none;
  text-align: right;
  color: #4A00E0;
  font-weight: bold;
}

html[dir="rtl"] .custom-table thead th {
  text-align: right;
}

html[dir="rtl"] .th-label-simulation {
  text-align: right !important;
}